import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Snackbar,
  Alert
} from "@mui/material";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";

// Function to shuffle events and return a random subset
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

// Custom styles for better UI
const useStyles = makeStyles({
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    fontFamily: "'Roboto', sans-serif"
  },
  title: {
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
    color: "#ff5252",
    "@media (max-width: 768px)": {
      display: "none" // Hide on mobile devices
    }
  },
  subtitle: {
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
    color: "#ff5252"
  },
  card: {
    borderRadius: "10px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
    marginBottom: "20px"
  },
  iframeWrapper: {
    position: "relative",
    paddingBottom: "56.25%",
    height: 0
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  noData: {
    textAlign: "center",
    fontSize: "18px",
    color: "#757575"
  }
});

const YouTubeSearch = () => {
  const classes = useStyles();
  const [eventsWithVideos, setEventsWithVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limitReached, setLimitReached] = useState(false);

  const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY; // Your YouTube API Key

  // Automatically use today's date
  const today = new Date();
  const formattedToday = format(today, "MMMM d"); // For display
  const cacheKey = `youtube_videos_${format(today, "yyyy-MM-dd")}`; // Unique cache key for the day

  // Retry mechanism: function to retry the API call up to 5 times
  const fetchWikipediaDataWithRetry = async (attempt = 1, maxRetries = 5) => {
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      setEventsWithVideos(JSON.parse(cachedData)); // Use cached data
      setLoading(false);
      return;
    }

    try {
      const month = format(today, "MM"); // Get month
      const day = format(today, "dd"); // Get day
      const response = await axios.get(
        `https://api.wikimedia.org/feed/v1/wikipedia/en/onthisday/all/${month}/${day}`
      );

      // Extract events from the response
      const { events } = response.data;

      // Shuffle the events to randomize their order and select a few to display
      const randomEvents = shuffleArray(events).slice(0, 5);
      await fetchYouTubeVideosForEvents(randomEvents); // Fetch videos for randomly selected events

      setLoading(false);
    } catch (error) {
      if (attempt < maxRetries) {
        console.warn(`Attempt ${attempt} failed. Retrying...`);
        fetchWikipediaDataWithRetry(attempt + 1); // Retry the call
      } else {
        console.error(`Failed after ${maxRetries} attempts:`, error);
        setLoading(false);
      }
    }
  };

  // Fetch YouTube videos for all historical events
  const fetchYouTubeVideosForEvents = async (eventsList) => {
    try {
      const eventsWithVideos = await Promise.all(
        eventsList.map(async (event, index) => {
          // Concatenate event text and year to make the search query more specific
          const searchQuery = `${event.text} ${event.year}`.trim();

          const searchParams = {
            part: "snippet",
            maxResults: 1, // Get one video per event
            q: searchQuery, // Include both event text and year in the search
            type: "video",
            key: apiKey
          };

          const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/search`,
            { params: searchParams }
          );

          const videoItem = response.data.items[0] || null; // Handle no video found
          return { event: eventsList[index], video: videoItem }; // Pair event with its video
        })
      );

      setEventsWithVideos(eventsWithVideos); // Update the state with events and their corresponding videos
      localStorage.setItem(cacheKey, JSON.stringify(eventsWithVideos)); // Save the result to localStorage
    } catch (error) {
      console.error("Error fetching videos from YouTube API:", error);
      if (error.response && error.response.status === 403) {
        setLimitReached(true); // API quota exceeded
      }
    }
  };

  useEffect(() => {
    fetchWikipediaDataWithRetry(); // Retry Wikipedia data up to 5 times if necessary
  }, []); // Empty dependency array ensures it runs once

  return (
    <div className={classes.container}>
      <Typography fontWeight="bold" variant="h1" className={classes.title}>
        YouTubeDay.com
      </Typography>

      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Typography
          fontFamily={"fantasy"}
          fontWeight="bold"
          variant="h4"
          className={classes.subtitle}
        >
          On This Day: {formattedToday}
        </Typography>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {eventsWithVideos.length > 0 ? (
            eventsWithVideos.map(({ event, video }, index) => (
              <Card key={index} className={classes.card}>
                <CardContent>
                  <Typography
                    variant="h7"
                    style={{ color: "#757575", marginBottom: "10px" }}
                  >
                    {event?.year ? (
                      <Typography
                        component="span"
                        variant="h6"
                        style={{ fontWeight: "bold" }}
                      >
                        {`${event.year}:`}
                      </Typography>
                    ) : (
                      ""
                    )}{" "}
                    {event?.text}
                  </Typography>
                  {video ? (
                    <>
                      <Typography
                        variant="h6"
                        marginTop={2}
                        marginBottom={1}
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        {video.snippet.title}
                      </Typography>

                      <div className={classes.iframeWrapper}>
                        <iframe
                          title={video.snippet.title}
                          className={classes.iframe}
                          src={`https://www.youtube.com/embed/${video.id.videoId}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{ color: "#ff5252", marginBottom: "10px" }}
                    >
                      No video available for this event.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="body1" className={classes.noData}>
              No events found for today.
            </Typography>
          )}
        </>
      )}

      {/* Snackbar to notify user when limit is reached */}
      <Snackbar
        open={limitReached}
        autoHideDuration={4000}
        onClose={() => setLimitReached(false)}
      >
        <Alert severity="error">You have reached the request limit!</Alert>
      </Snackbar>
    </div>
  );
};

export default YouTubeSearch;
